import "core-js/modules/es.array.push.js";
import { getEvaOption, saveEvaOption } from "@/api/api";
export default {
  name: "insureList",
  data() {
    return {
      tableData: [],
      Info: [],
      dialogFormVisible: false,
      inputVisible: false,
      inputValue: '',
      inputVisible1: false,
      inputValue1: '',
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
    },
    getList() {
      getEvaOption().then(res => {
        this.tableData = res.data;
      });
    },
    showEdit(row) {
      this.dialogFormVisible = true;
      this.Info = row;
    },
    showInput() {
      this.inputVisible = true;
    },
    handleInputConfirm() {
      if (this.inputValue) {
        this.Info.teacherTips.push({
          option_id: this.Info.id,
          name: this.inputValue,
          option_name: this.Info.name,
          type: '教师评价'
        });
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    delTip(index) {
      this.Info.teacherTips.splice(index, 1);
    },
    showInput1() {
      this.inputVisible1 = true;
    },
    handleInputConfirm1() {
      if (this.inputValue1) {
        this.Info.systemTips.push({
          option_id: this.Info.id,
          name: this.inputValue1,
          option_name: this.Info.name,
          type: '系统评价'
        });
      }
      this.inputVisible1 = false;
      this.inputValue1 = '';
    },
    delTip1(index) {
      this.Info.systemTips.splice(index, 1);
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    subData() {
      saveEvaOption(this.Info).then(res => {
        if (res.success) {
          this.dialogFormVisible = false;
        }
        this.$root.msgResult(res, '', this, 'getList');
      });
    }
  }
};